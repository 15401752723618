<script setup lang="ts">
import { flattenEntity } from 'strapi-flatten-graphql'
import type { TestimonialV2 } from '~/models/Strapi'

interface Props {
  datas: any
}

const props = defineProps<Props>()
const breakpoints = {
  300: {
    itemsToShow: 1,
  },
  640: {
    itemsToShow: 1,
  },
  1024: {
    itemsToShow: 1,
  },
}
const testimoni = !isEmpty(props.datas.testimoni)
  ? props.datas.testimoni.map(item => ({
    ...item,
    thumbnail: !isEmpty(item.thumbnail?.data) ? flattenEntity(item.thumbnail?.data)?.url : null,
    flag: !isEmpty(item.flag?.data) ? flattenEntity(item.flag?.data)?.url : null,
  }))
  : []
const flattenData = { ...props.datas, testimoni } as TestimonialV2
</script>

<template>
  <BaseAtomsSkeletonBounded :class="`${flattenData?.max_width ? flattenData?.max_width : ''}`" class="text-gray-900" container="full">
    <h2 class="text-2xl lg:text-4xl font-bold text-center mb-6">
      {{ flattenData?.title }}
    </h2>

    <!-- TESTIMONIAL SECTION : DESKTOP -->
    <div class="grid-cols-2 lg:grid-cols-4 gap-x-6 md:gap-y-8 lg:gap-y-10 <md:hidden md:grid">
      <div v-for="(item, idx) in flattenData?.testimoni" :key="idx" :class="`${idx === 0 || idx === 5 ? 'col-span-2' : 'col-span-1'} relative p-6 shadow-lg rounded-md h-[230px] lg:h-[250px]`">
        <img src="/images/quotes-right.svg" class="w-6 h-6 mb-2">
        <p :class="`${idx === 0 || idx === 5 ? 'text-xl' : 'text-sm'}`">
          {{ item.description }}
        </p>
        <div class="flex items-center mt-8 absolute bottom-5">
          <img v-if="item.thumbnail" :src="item.thumbnail" class="rounded-full mr-4 object-cover w-10 h-10">
          <div v-else class="flex-shrink-0 rounded-full mr-4 bg-[#5843E5] flex items-center w-10 h-10">
            <Icon name="heroicons:heart-solid" class="w-4 h-4 text-white mx-auto" />
          </div>
          <div>
            <p class="font-bold text-sm">
              {{ item.name }}
            </p>
            <div class="text-xs flex items-center text-gray-600">
              {{ item.location }}
              <img v-if="item.flag" :src="item.flag" class="object-cover w-4 h-4 ml-1">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TESTIMONIAL SECTION : MOBILE -->
    <div id="carousel-container" class="<md:block md:hidden">
      <Carousel snap-align="start" :items-to-scroll="1" :breakpoints="breakpoints" :wrap-around="true">
        <Slide v-for="idx in Math.ceil(flattenData?.testimoni.length / 3)" :key="idx" class="!block cursor-pointer grid gap-x-6 lg:py-2 -mt-2">
          <div v-for="start in 3" :key="start" class="mb-2">
            <div class="relative p-6 shadow-lg rounded-md h-[250px]">
              <img src="/images/quotes-right.svg" class="w-6 h-6 mb-2">
              <p class="text-sm">
                {{ flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.description }}
              </p>
              <div class="flex items-center mt-8 absolute bottom-5">
                <img v-if="flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.thumbnail" :src="flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.thumbnail" class="rounded-full mr-4 object-cover w-10 h-10">
                <div v-else class="flex-shrink-0 rounded-full mr-4 bg-indigo-600 flex items-center w-10 h-10">
                  <Icon name="heroicons:heart-solid" class="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <p class="font-bold text-sm">
                    {{ flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.name }}
                  </p>
                  <div class="text-xs flex items-center text-gray-600">
                    {{ flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.location }}
                    <img v-if="flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.flag" :src="flattenData?.testimoni[start + ((idx - 1) * 3) - 1]?.flag" class="object-cover w-4 h-4 ml-1">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Pagination class="!mt-6" />
        </template>
      </Carousel>
    </div>
  </BaseAtomsSkeletonBounded>
</template>

<style scoped>
#carousel-container :deep(.carousel) {
    width: calc(100% + 16px);
    transform: translateX(-8px)
}
#carousel-container :deep(.carousel__slide) {
    padding-left: 8px;
    padding-right: 8px;
}
#carousel-container :deep(.carousel__pagination-button){
  margin-right: 4px;
}
#carousel-container :deep(.carousel__pagination-button:after){
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background: #E5E7EB
}

#carousel-container :deep(.carousel__pagination-button:hover::after), #carousel-container :deep(.carousel__pagination-button--active::after){
  background: #5843E5;
}
</style>
